import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["objective"];
  connect() {
    fetch(`/get_event_los`)
      .then((response) => response.json())
      .then((json) => {
        console.log("event_los", json);
        BuildLayout(json);
      })
      .catch((error) => console.log("error", error));

    const BuildLayout = (events_array) => {
      let els = this.objectiveTargets;
      console.log("Hello, LO EVENTS!", els);
      els.forEach((el, i) => {
        console.log("EL: ", el);
        let obj = el.dataset.objective;
        let obj_url = `https://basehospitaladmin.com/learning_objectives/${obj}.json`;
        let events = events_array.filter((e) => e["url"] === obj_url);
        console.log("ARRAY: ", events);
        let text = events.length;
        el.innerHTML = `${text} events.`;
        if (text > 0) {
          el.classList.add("count-badge");
        } else {
          el.classList.add("zero-count-badge");
        }
      });
    };
  }
}
