import {
    Controller
} from "stimulus"

export default class extends Controller {


    connect() {
        this.setCircles()
        this.setIndicator()
    }

    clearScaffold = () => {
        document.getElementById('level-selector-bottom').value = ""
        document.getElementById('level-selector-top').value = ""
        const display_element_top = document.getElementById('selected-value-top');
        display_element_top.innerHTML = '';
        const display_element = document.getElementById('selected-value-bottom');
        display_element.innerHTML = '';
        const all_circles = document.getElementsByTagName('ellipse');
        for (var i = 0; i < all_circles.length; i++) {
            all_circles[i].setAttribute("fill", "#F35627");
        }
    }

    setCircles = () => {
        const current_bottom = parseInt(document.getElementById('level-selector-bottom').value)
        const current_top = parseInt(document.getElementById('level-selector-top').value)
        const all_circles = document.getElementsByTagName('ellipse');
        for (var i = 0; i < all_circles.length; i++) {
            const current_circle = all_circles[i]
            console.log(current_circle)
            const circle_value = parseInt(current_circle.parentNode.getElementsByTagName('text')[0].textContent)
            console.log(circle_value)
            if ((circle_value <= current_top && circle_value >= current_bottom) || (Number.isNaN(current_top) && circle_value == current_bottom)) {
                console.log(true)
                all_circles[i].setAttribute("fill", "#F35627");
            } else {
                console.log(false)
                all_circles[i].setAttribute("fill", "#9AA5B1");
            }
        }
    }

    setIndicator = () => {
        const current_bottom = parseInt(document.getElementById('level-selector-bottom').value)
        const current_top = parseInt(document.getElementById('level-selector-top').value)
        const display_element_top = document.getElementById('selected-value-top');
        const display_element_bottom = document.getElementById('selected-value-bottom');
        if (!Number.isNaN(current_bottom)) {
            const h1 = document.createElement("h1")
            h1.innerHTML = current_bottom;
            display_element_bottom.innerHTML = '';
            display_element_bottom.appendChild(h1)
        }
        if (!Number.isNaN(current_top)) {
            const h1 = document.createElement("h1")
            h1.innerHTML = current_top;
            display_element_top.innerHTML = '';
            display_element_top.appendChild(h1)
        }
    }


    selected = (event) => {
        event.preventDefault()
        const current_bottom = parseInt(document.getElementById('level-selector-bottom').value)
        const current_top = parseInt(document.getElementById('level-selector-top').value)
        console.log(current_top)
        console.log(current_bottom)
        const parent_anchor = event.target.closest('a');
        const value = parseInt(parent_anchor.dataset.level);
        /* const h1 = document.createElement("h1")
        h1.innerHTML = value;
        const display_element = document.getElementById('selected-value');
        display_element.innerHTML = '';
        display_element.appendChild(h1) */

        if (Number.isNaN(current_bottom) || value < current_bottom) {
            document.getElementById('level-selector-bottom').value = value;
        } else {
            document.getElementById('level-selector-top').value = value;
        }
        this.setIndicator()
        this.setCircles()

    }
}