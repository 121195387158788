import { Controller } from "stimulus";

const updateSelectedList = (json, type = "scenarios") => {
  let ul = null;
  let data_action = "";
  let list = [];
  if (type === "scenarios") {
    list = json.scenarios;
    ul = document.getElementById("evt-selected-list");
    data_action = "click->event-scenario-select#removeScenario";
  } else if (type === "skills") {
    list = json.skills;
    ul = document.getElementById("evt-selected-skill-list");
    data_action = "click->event-scenario-select#removeSkill";
  } else if (type === "teaching_resources") {
    list = json.teaching_resources;
    ul = document.getElementById("evt-selected-teaching-resource-list");
    data_action = "click->event-scenario-select#removeTeachingResource";
  }
  ul.innerHTML = "";
  list.forEach((item) => {
    let li = document.createElement("li");
    let span = document.createElement("span");
    span.className = "pull-right";
    let icon = document.createElement("i");
    icon.setAttribute("data-action", data_action);
    span.appendChild(icon);
    icon.className = "fa fa-trash";
    li.className = "list-group-item evt-scenario-select";
    li.textContent = item.name;
    li.setAttribute("data-id", item.id);
    li.appendChild(span);
    ul.appendChild(li);
  });
  updateDropdown(list, type);
};

const updateDropdown = (list, type = "scenarios") => {
  let ul = null;
  let data_action = "";
  if (type === "scenarios") {
    ul = document.getElementById("evt-scenario-list");
    data_action = "click->event-scenario-select#scenarioSelect";
  } else if (type === "skills") {
    ul = document.getElementById("evt-skill-list");
    data_action = "click->event-scenario-select#skillSelect";
  } else if (type == "teaching_resources") {
    ul = document.getElementById("evt-teaching-resource-list");
    data_action = "click->event-scenario-select#teachingResourceSelect";
  }

  let lis = ul.getElementsByTagName("li");
  for (var i = 0; i < lis.length; i++) {
    let item_id = lis[i].dataset.id;
    let found = list.find((s) => `${s.id}` === item_id);
    if (
      !found &&
      !lis[i].classList.contains("event-available-scenarios-header")
    ) {
      lis[i].className = "list-group-item";
      lis[i].setAttribute("data-action", data_action);
    } else if (!lis[i].classList.contains("event-available-scenarios-header")) {
      lis[i].className = "list-group-item list-group-item-success";
      lis[i].setAttribute("data-action", "");
    }
  }
};

const buildSelectorList = (json, selected_list, type = "scenarios") => {
  let list = [];
  let ul = null;
  let data_action = "";
  if (type === "scenarios") {
    list = json.scenarios;
    ul = document.getElementById("evt-scenario-list");
    data_action = "click->event-scenario-select#scenarioSelect";
  } else if (type === "skills") {
    list = json.skills;
    ul = document.getElementById("evt-skill-list");
    data_action = "click->event-scenario-select#skillSelect";
  } else if (type === "teaching_resources") {
    list = json.teaching_resources;
    ul = document.getElementById("evt-teaching-resource-list");
    data_action = "click->event-scenario-select#teachingResourceSelect";
  }
  ul.innerHTML = "";
  let header = document.createElement("li");
  header.className = "event-available-scenarios-header list-group-item";
  let human_readable_type = type.replace(/_/g, " ");
  header.textContent =
    selected_list.length > 0
      ? `Available ${human_readable_type}, click to select`
      : `There are no ${human_readable_type} in the selected category.`;
  ul.appendChild(header);
  console.log("LIST", list);
  selected_list.forEach((scenario) => {
    let found = list.find((i) => i.id === scenario.id);
    console.log("FOUND", found);
    let li = document.createElement("li");
    li.className = found
      ? "list-group-item list-group-item-success"
      : "list-group-item evt-scenario-select";
    li.textContent = scenario.name;
    if (!found) {
      li.setAttribute("data-action", data_action);
    }
    li.setAttribute("data-id", scenario.id);
    ul.appendChild(li);
  });
};

const getList = (callback, selected_list = [], url, type) => {
  fetch(url)
    .then((response) => response.json())
    .then((json) => {
      callback(json, selected_list, type);
    });
};

export default class extends Controller {
  connect() {
    console.log("CONNECTING TO EVENT SCENARIO SELECT");
  }

  categorySelect = (e) => {
    let event_id = this.element.dataset.eventid;
    fetch(`/scenario_categories/${e.target.value}.json`)
      .then((response) => response.json())
      .then((json) => {
        getList(
          buildSelectorList,
          json.scenarios,
          `/event_scenario_list/${event_id}.json`,
          "scenarios"
        );
      })
      .catch((error) => console.log("error", error));
  };

  skillCategorySelect = (e) => {
    let event_id = this.element.dataset.eventid;
    fetch(`/skill_categories/${e.target.value}.json`)
      .then((response) => response.json())
      .then((json) => {
        getList(
          buildSelectorList,
          json.skills,
          `/event_skill_list/${event_id}.json`,
          "skills"
        );
      })
      .catch((error) => console.log("error", error));
  };

  teachingResourceCategorySelect = (e) => {
    let event_id = this.element.dataset.eventid;
    let url = encodeURI(
      `/teaching_resources_by_type/?format=json&type=${e.target.value}`
    );
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        console.log("JSON", json);
        getList(
          buildSelectorList,
          json,
          `/event_teaching_resource_list/${event_id}.json`,
          "teaching_resources"
        );
      })
      .catch((error) => console.log("error", error));
  };

  skillSelect = (e) => {
    e.target.dataset.action = "";
    e.target.className = "list-group-item list-group-item-success";
    let data = {
      event: {
        skill_id: e.target.dataset.id,
        event_id: this.element.dataset.eventid,
        action: "add",
      },
    };
    this.databaseUpdate(data, "skills");
  };

  scenarioSelect = (e) => {
    e.target.dataset.action = "";
    e.target.className = "list-group-item list-group-item-success";
    let data = {
      event: {
        scenario_id: e.target.dataset.id,
        event_id: this.element.dataset.eventid,
        action: "add",
      },
    };
    this.databaseUpdate(data, "scenarios");
  };

  teachingResourceSelect = (e) => {
    e.target.dataset.action = "";
    e.target.className = "list-group-item list-group-item-success";
    let data = {
      event: {
        teaching_resource_id: e.target.dataset.id,
        event_id: this.element.dataset.eventid,
        action: "add",
      },
    };
    this.databaseUpdate(data, "teaching_resources");
    //e.target.value = "";
  };

  removeScenario = (e) => {
    let li = e.target.closest("li");
    let data = {
      event: {
        scenario_id: li.dataset.id,
        event_id: this.element.dataset.eventid,
        action: "remove",
      },
    };
    this.databaseUpdate(data, "scenarios");
  };

  removeSkill = (e) => {
    let li = e.target.closest("li");
    let data = {
      event: {
        skill_id: li.dataset.id,
        event_id: this.element.dataset.eventid,
        action: "remove",
      },
    };
    this.databaseUpdate(data, "skills");
  };

  removeTeachingResource = (e) => {
    let li = e.target.closest("li");
    let data = {
      event: {
        teaching_resource_id: li.dataset.id,
        event_id: this.element.dataset.eventid,
        action: "remove",
      },
    };
    this.databaseUpdate(data, "teaching_resources");
  };

  databaseUpdate = (data, type) => {
    let url = `/add_events_${type}/`;
    $.ajax({
      url: url,
      method: "POST",
      contentType: "application/json",
      data: JSON.stringify(data),
      success: function (response) {
        updateSelectedList(response, type);
      },
      error: function (response) {
        console.log("ERROR", response);
      },
    });
  };
}
