import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let e = this.element;
    let item_array = [];
    for (const a of document.querySelectorAll("label")) {
      if (a.textContent.includes("Start of timed event")) {
        let checkbox = a.parentElement.querySelectorAll("input")[1];

        if (checkbox.checked) {
          let start_item_wrapper = a.closest(".nested-fields");
          let item_name =
            start_item_wrapper.getElementsByClassName("item-name")[0].value;
          item_array.push(item_name);
        }
      }
    }
    let wrapper = e.closest(".nested-fields");
    let selector = wrapper.getElementsByClassName("start-item-selector")[0];
    let placeholder = document.createElement("option");
    placeholder.value = "";
    placeholder.text = "Select Start Item";
    selector[0] = placeholder;
    for (const i of item_array) {
      let option = document.createElement("option");
      option.value = i;
      option.textContent = i;
      selector.appendChild(option);
    }
    let selected_name = e.dataset.startItemName;
    if (selected_name) {
      selector.value = selected_name;
    }
    console.log("SELECTED NAME", selected_name);
  }
  endTimeChanged(e) {
    console.log("Changed", e.target.checked);
    if (e.target.checked) {
      let item_array = [];
      for (const a of document.querySelectorAll("label")) {
        if (a.textContent.includes("Start of timed event")) {
          let checkbox = a.parentElement.querySelectorAll("input")[1];

          if (checkbox.checked) {
            let start_item_wrapper = a.closest(".nested-fields");
            let item_name =
              start_item_wrapper.getElementsByClassName("item-name")[0].value;
            item_array.push(item_name);
          }
        }
      }
      let wrapper = e.target.closest(".nested-fields");
      let selector = wrapper.getElementsByClassName("start-item-selector")[0];
      selector.innerHTML = "";
      let placeholder = document.createElement("option");
      placeholder.value = "";
      placeholder.text = "Select Start Item";
      selector[0] = placeholder;
      for (const i of item_array) {
        let option = document.createElement("option");
        option.value = i;
        option.textContent = i;
        selector.appendChild(option);
      }
    } else {
      this.clearStartTimes(e);
    }
  }
  clearStartTimes(e) {
    console.log("RESETTING");
    let wrapper = e.target.closest(".nested-fields");
    let selector = wrapper.getElementsByClassName("start-item-selector")[0];
    console.log("Selector", selector);
    for (const s of selector.options) {
      console.log("index", s.index);
      if (s.index === 0) {
        console.log("Setting");
        s.setAttribute("selected", "selected");
      } else {
        console.log("removing");
        s.removeAttribute("selected");
      }
    }
  }
}
