import { Controller } from "stimulus";
let myPlayer = {};

const Pause = (myPlayer, element) => {
  console.log("PAUSING");
  myPlayer.pause();
  let time = myPlayer.currentMediaTime();
  element.dataset.time = time;
  const text_area = document.getElementById("annotation");
  text_area.focus();
  text_area.addEventListener("blur", (event) => {
    console.log("EVENT", event);
    Submit();
    myPlayer.play();
    //console.log("MY PLAYER PLAY", myPlayer.playing());
  });
};

const Submit = () => {
  //e.preventDefault();
  let active_video_item = document.querySelector(".list-group-item.active");
  let video_key = active_video_item.dataset.url;
  let name_wrapper = active_video_item.querySelector(".video-filename");
  let video_name = name_wrapper.innerHTML;
  const element = document.getElementById("root-element");
  let review = element.dataset.review;
  const annotation_text = document.getElementById("annotation").value;
  if (annotation_text.length > 1) {
    console.log("SUBMITTING", annotation_text);
    $.ajax({
      type: "POST",
      url: "/annotations",
      data: {
        annotation: {
          video_key: video_key,
          video_name: video_name,
          annotation: annotation_text,
          intubation_review_id: review,
          video_time: element.dataset.time,
        },
      },
      success(data) {
        return false;
      },
      error(data) {
        console.log("%%%ERROR", data);
        return false;
      },
    });
    document.getElementById("annotation").value = "";
  }
};

export default class extends Controller {
  static targets = ["annotation"];
  pause() {
    let root = this.element;
    Pause(myPlayer, root);
  }
  setActiveVideo = (e) => {
    console.log("SET ACTIVE VIDEO", e.target);
    let video_list = document.getElementById("video-list");
    let list_items = video_list.getElementsByClassName("list-group-item");
    for (var i = 0; i < list_items.length; i++) {
      list_items[i].classList.remove("active");
    }

    let list_item = e.target.closest(".list-group-item");
    console.log("LIST ITEM", list_item);
    let url = list_item.dataset.url;
    list_item.classList.add("active");
    this.startPlayer(url);
  };

  startPlayer = (url, time = 0.0) => {
    console.log("TIME", time);
    console.log("IS IT GREATER", time > 0);
    let root = this.element;
    var myOptions = {
      repeat: false,
      autoplay: false,
      controls: true,
      width: this.element.clientWidth - 0.45 * this.element.clientWidth,
      poster: "",
      logo: {
        enabled: false,
      },
    };

    myPlayer = amp("azuremediaplayer", myOptions, goToTime(time));
    myPlayer.src([
      {
        src: url,
      },
    ]);

    document
      .getElementById("annotation")
      .addEventListener("input", function (e) {
        if (e.target.value !== "") {
          let is_playing = myPlayer.hasStarted() && !myPlayer.paused();
          console.log("IS PLAYIONG", is_playing);
          if (is_playing) {
            console.log("WILL PAUSE");
            Pause(myPlayer, root);
          }
        } else {
          console.log("hide button");
        }
      });
    //
    function goToTime(time) {
      console.log("CALLBACK", time);
      setTimeout(() => {
        if (time > 0) {
          myPlayer.currentTime(time);
          //myPlayer.autoplay(true);
        }
      }, 200);
    }
  };

  connect() {
    let video_list = document.getElementById("video-list");
    let list_items = video_list.getElementsByClassName("list-group-item");
    list_items[0].classList.add("active");
    let url = list_items[0].dataset.url;

    this.startPlayer(url);
    //   var myOptions = {
    //     repeat: false,
    //     autoplay: false,
    //     controls: true,
    //     width: this.element.clientWidth - 0.45 * this.element.clientWidth,
    //     poster: "",
    //     logo: {
    //       enabled: false,
    //     },
    //   };

    //   myPlayer = amp("azuremediaplayer", myOptions);
    //   myPlayer.src([
    //     {
    //       src: url,
    //     },
    //   ]);
    //   document
    //     .getElementById("annotation")
    //     .addEventListener("input", function (e) {
    //       if (e.target.value !== "") {
    //         let is_playing = myPlayer.hasStarted() && !myPlayer.paused();
    //         console.log("IS PLAYIONG", is_playing);
    //         if (is_playing) {
    //           console.log("WILL PAUSE");
    //           Pause(myPlayer, root);
    //         }
    //       } else {
    //         console.log("hide button");
    //       }
    //     });
  }

  to(e) {
    let parent = e.target.closest(".annotation-header");
    console.log("ELEMENT", parent);
    let time = parseFloat(parent.dataset.time);
    let url = parent.dataset.url;
    console.log("TIME STAMP", time);
    console.log("URL", url);
    this.startPlayer(url, time);
    let list_item = document.querySelector(`[data-url="${url}"]`);
    console.log("LIST ITEM", list_item);
    let video_list = document.getElementById("video-list");
    let list_items = video_list.getElementsByClassName("list-group-item");
    for (var i = 0; i < list_items.length; i++) {
      list_items[i].classList.remove("active");
    }
    list_item.classList.add("active");
    //myPlayer.currentTime(time);
  }
}
