import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "checkbox"];
  connect() {
    let checkboxes = this.checkboxTargets;
    let value = this.inputTarget.value;
    let default_choice = this.element.dataset.default;
    console.log("default_choice", default_choice);
    if (value == "") {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].dataset.textValue == default_choice) {
          checkboxes[i].classList.add("cqi-checkbox-filled");
          this.inputTarget.value = default_choice;
        }
      }
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].dataset.textValue == value) {
          checkboxes[i].classList.add("cqi-checkbox-filled");
        }
      }
    }
  }

  selected(event) {
    let checkboxes = this.checkboxTargets;
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].classList.remove("cqi-checkbox-filled");
    }
    let value = event.target.dataset.textValue;
    event.target.classList.add("cqi-checkbox-filled");
    this.inputTarget.value = value;
  }
}
