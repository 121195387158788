// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["filter"];

  connect() {
    this.attachedCompetencies();
    this.nocps();
  }

  setFilter(event) {
    event.preventDefault();
    let value = event.target.dataset.value;
    if (value == "nocps") {
      this.element.dataset.filter = "nocps";
      this.nocps();
    }
    if (value == "non-nocps") {
      this.element.dataset.filter = "non-nocps";
      this.nonNocps();
    }
    if (value == "all") {
      this.element.dataset.filter = "all";
      this.allCompetencies();
    }
    this.filterTargets.forEach((element) => {
      element.classList.remove("active");
    });
    event.target.classList.add("active");

    console.log("FILTER", value);
  }

  nocps() {
    fetch("/learning_objectives/nocps.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("NOCPS", data);
        this.buildPickerList(data);
      });
  }

  nonNocps() {
    fetch("/learning_objectives/non_nocps.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("NON NOCPS", data);
        this.buildPickerList(data);
      });
  }

  allCompetencies() {
    fetch("/learning_objectives/all_competencies.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("ALL", data);
        this.buildPickerList(data);
      });
  }

  removeCompetency(event) {
    if (this.isAddingCompetency) return; // Prevents the function from running if it's already in process
    this.isAddingCompetency = true;
    let filter = this.element.dataset.filter;
    let competency_id = event.currentTarget.dataset.id;
    let course_id = this.element.dataset.courseId;
    console.log("REMOVE", competency_id, course_id);

    fetch(
      `/courses/${course_id}/remove_objective?learning_objective_id=${competency_id}`
    )
      .then((response) => {
        if (response.ok) {
          this.attachedCompetencies();
          if (filter == "nocps") {
            this.nocps();
          } else if (filter == "non-nocps") {
            this.nonNocps();
          } else {
            this.allCompetencies();
          }
        } else {
          alert("Something went wrong");
        }
      })
      .finally(() => {
        this.isAddingCompetency = false; // Reset the flag when the fetch is complete
      });
  }

  attachedCompetencies() {
    let course_id = this.element.dataset.courseId;
    console.log("COURSE ID", this.element.dataset);
    fetch(`/courses/${course_id}/attached_objectives.json`)
      .then((response) => response.json())
      .then((data) => {
        console.log("ATTACHED", data);
        this.buildAttachedList(data);
        return data;
      });
  }
  initialize() {
    this.isAddingCompetency = false;
  }
  addCompetency(event) {
    if (this.isAddingCompetency) return; // Prevents the function from running if it's already in process
    this.isAddingCompetency = true;
    let filter = this.element.dataset.filter;
    let competency_id = event.currentTarget.dataset.id;
    let course_id = this.element.dataset.courseId;
    console.log("ADD", competency_id, course_id);

    fetch(
      `/courses/${course_id}/add_objective?learning_objective_id=${competency_id}`
    )
      .then((response) => {
        if (response.ok) {
          this.attachedCompetencies();
          if (filter == "nocps") {
            this.nocps();
          } else if (filter == "non-nocps") {
            this.nonNocps();
          } else {
            this.allCompetencies();
          }
        } else {
          alert("Something went wrong");
        }
      })
      .finally(() => {
        this.isAddingCompetency = false; // Reset the flag when the fetch is complete
      });
  }

  attachedCompetencies() {
    let course_id = this.element.dataset.courseId;
    console.log("COURSE ID", this.element.dataset);
    return fetch(`/courses/${course_id}/attached_objectives.json`) // Return the fetch promise
      .then((response) => response.json())
      .then((data) => {
        console.log("ATTACHED", data);
        this.buildAttachedList(data);
        return data; // Resolve with the data
      });
  }

  buildAttachedList(data) {
    let scrollPosition = document.getElementById("attached-ul")?.scrollTop;
    let list = document.createElement("ul");
    list.id = "attached-ul";

    console.log("SCROLL", scrollPosition);
    list.classList.add("list-group", "scrollable-list");
    let last_category = "";
    data.forEach((element) => {
      if (last_category != element.category) {
        let category = document.createElement("li");
        category.classList.add(
          "list-group-item",
          "disabled",
          "lo-category-list-item"
        );
        category.innerText = element.category;
        list.appendChild(category);
        last_category = element.category;
      }
      let item = document.createElement("li");

      item.classList.add("list-group-item", "lo-list-list-item");
      let remove_button = document.createElement("span");
      remove_button.dataset.id = element.id;
      remove_button.dataset.action =
        "click->competency-picker#removeCompetency";
      remove_button.classList.add("remove-comp-btn");
      let icon = document.createElement("i");
      icon.classList.add("fa", "fa-trash");
      remove_button.appendChild(icon);
      let name_line = document.createElement("div");
      name_line.classList.add("comp-list-item-row");
      name_line.innerText = element.name;
      name_line.appendChild(remove_button);
      item.appendChild(name_line);

      if (element.nocp) {
        let perf_env = document.createElement("div");
        perf_env.classList.add("nocp-perf-env");
        perf_env.innerText = element.description?.split("\n")[0];
        item.appendChild(perf_env);
      }
      list.appendChild(item);
    });

    document.getElementById("attached-list").innerHTML = "";
    document.getElementById("attached-list").appendChild(list);
    document.getElementById("attached-ul").scrollTop = scrollPosition;
  }

  async buildPickerList(data) {
    let scrollPosition = document.getElementById("picker-ul")?.scrollTop;

    let attachedList = await this.attachedCompetencies(); // Wait for the attached competencies
    let list = document.createElement("ul");
    list.id = "picker-ul";
    list.classList.add("list-group", "scrollable-list");
    let last_category = "";
    data.forEach((element) => {
      if (last_category != element.category) {
        let category = document.createElement("li");
        category.classList.add(
          "list-group-item",

          "lo-category-list-item"
        );
        let name_div = document.createElement("div");
        name_div.classList.add("comp-list-item-row");
        name_div.innerText = element.category;
        category.appendChild(name_div);
        list.appendChild(category);
        last_category = element.category;
      }
      let item = document.createElement("li");
      if (attachedList.some((attached) => attached.id === element.id)) {
        item.dataset.id = element.id;
        item.classList.add(
          "list-group-item",
          "lo-list-list-item",
          "disabled",
          "strikethrough"
        );
        let name_line = document.createElement("div");
        name_line.innerText = element.name;
        item.appendChild(name_line);
        if (element.nocp) {
          let perf_env = document.createElement("div");
          perf_env.classList.add("nocp-perf-env");
          perf_env.innerText = element.description?.split("\n")[0];
          item.appendChild(perf_env);
        }
      } else {
        item.dataset.action = "click->competency-picker#addCompetency";
        item.dataset.id = element.id;
        item.classList.add("list-group-item", "lo-list-list-item", "clickable");
        let name_line = document.createElement("div");
        name_line.innerText = element.name;

        item.appendChild(name_line);
        if (element.nocp) {
          let perf_env = document.createElement("div");
          perf_env.classList.add("nocp-perf-env");
          perf_env.innerText = element.description?.split("\n")[0];
          item.appendChild(perf_env);
        }
      }
      list.appendChild(item);
    });

    document.getElementById("picker-list").innerHTML = "";
    document.getElementById("picker-list").appendChild(list);
    document.getElementById("picker-ul").scrollTop = scrollPosition;
  }
}
