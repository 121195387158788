import { Controller } from "stimulus";
import Dropzone from "dropzone";

import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  static targets = ["input"];
  connect() {
    console.log("Hello, Dropzone!");
    Dropzone.autoDiscover = false;
    this.inputTarget.disable = true;
    this.inputTarget.style.display = "none";
    const dropzone = new Dropzone(this.element, {
      url: "/",
      maxFilesize: 3000,
      maxFiles: 1,
      autoQueue: false,
    });
    class Uploader {
      directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener("progress", (event) => {
          this.directUploadDidProgress(event);
          console.log("PROGRESS", event);
        });
      }
      directUploadDidProgress(event) {
        let bar = document.getElementsByClassName("progress-bar")[0];
        let progress_rect = null;
        if (document.getElementsByClassName("ir-progress-bar").length > 0) {
          progress_rect = document.getElementsByClassName("ir-progress-bar")[0];
        } else {
          progress_rect = document.createElement("div");
        }
        progress_rect.className = "ir-progress-bar";
        let progress_percent = event.loaded / event.total;
        console.log("PROGR EVENT", progress_percent);
        progress_rect.style.width = `${progress_percent * 256}px`;
        bar.appendChild(progress_rect);
      }
    }
    const uploader = new Uploader();

    dropzone.on("addedfile", (file) => {
      console.log("Added file", file);
      setTimeout(() => {
        if (file.accepted) {
          const upload = new DirectUpload(file, this.url, uploader);

          upload.create((error, blob) => {
            console.log("Creating", blob.signed_id);
            this.hiddentInput = document.createElement("input");
            this.hiddentInput.type = "hidden";
            this.hiddentInput.name = this.inputTarget.name;
            this.hiddentInput.value = blob.signed_id;
            this.inputTarget.parentNode.insertBefore(
              this.hiddentInput,
              this.inputTarget.nextSibling
            );
            dropzone.emit("success", file);
            dropzone.emit("complete", file);
          });
        }
      }, 2000);
    });

    dropzone.on("success", (file) => {
      setTimeout(() => {
        alert("The file has been uploaded");
      }, 1000);
    });
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }
}
