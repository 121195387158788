//Create a stimulusjs controller

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cqiId"];

  connect() {
    console.log("Hello, CQI ID!", this.cqiIdTarget.innerHTML);
  }

  fieldChanged(event) {
    console.log("fieldChanged", event.target.value);
    this.cqiIdTarget.innerHTML = event.target.value;
  }
}
