import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["template", "selected", "search"];
  connect() {
    fetch("/learning_objectives.json")
      .then((response) => response.json())
      .then((json) => {
        buildList(json, selected_ids_array);
      })
      .catch((error) => console.log("error", error));
    let selected_ids_array = [];
    let selected = document.getElementsByClassName("selected-objectives");
    for (var i = 0; i < selected.length; i++) {
      let el = selected[i];
      selected_ids_array.push(el.dataset.recid);
    }
  }

  search(e) {
    let items = document.getElementsByClassName("available");
    for (var i = 0; i < items.length; i++) {
      let el = items[i];
      let text = el.innerHTML;
      if (text.search(e.target.value) == -1) {
        el.classList.add("hidden");
      } else {
        el.classList.remove("hidden");
      }
    }
  }

  onPress(e) {
    this.addToSelected(e);
  }

  addToSelected(e) {
    let link_area = e.target.closest(".link-area");
    link_area.classList.add("already-used");
    link_area.dataset.action = "";
    let rec_id = link_area.dataset.recid;
    let new_id = new Date().getTime();
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new_id);
    this.selectedTarget.innerHTML += content;
    let new_element = document.querySelector(
      `#learning_objective_epas_learning_objectives_attributes_${new_id}_epa_id`
    );
    new_element.value = rec_id;
    new_element.parentNode.innerHTML += String(link_area.innerHTML);
  }

  remove(e) {
    let link_area = e.target.closest("li");
    if (link_area.dataset.persisted == "true") {
      let destroy_field = link_area.querySelector("input");
      destroy_field.value = 1;
      link_area.classList.add("hidden");
    } else {
      link_area.remove();
    }
  }
}

const buildList = (json, selected_ids_array) => {
  let list = document.getElementById("objectives-selector-list");
  document.getElementById("loading").remove();
  for (var i = 0; i < json.length; i++) {
    let record = json[i];
    console.log("RECRD", record);
    let el = document.createElement("li");
    el.setAttribute("data-recid", record.id);
    el.classList.add("list-group-item");
    el.classList.add("available");
    if (selected_ids_array.includes(String(record.id))) {
      el.classList.add("already-used");
    } else {
      el.setAttribute("data-action", "click->associate-objectives#onPress");
      el.classList.add("link-area");
    }
    el.innerHTML = `<span class="lo-category-text">${record.objective_type}</span> <br> <span class="lo-category-text">${record.name}</span> <br /> <span class='lo-text'> ${record.category} </span>`;
    list.append(el);
  }
};
