import { Controller } from "stimulus";

export default class extends Controller {
  connect() {}

  openNav() {
    document.getElementById("side-nav").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
    document.getElementById("openbtn").classList.add("hidden");
  }

  closeNav() {
    document.getElementById("side-nav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
    document.getElementById("openbtn").classList.remove("hidden");
  }
}
