import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["events"];
  connect() {
    console.log("Hello, fm gets!");
  }

  getCourses = () => {
    var res_array = null;
    let id = parseInt(this.data.get("loid"));
    console.log("ID", id);
    fetch(`/get_lo_events?lo_id=${id}`)
      .then((response) => response.json())
      .then((json) => {
        res_array =
          json["data"][0]["fieldData"]["array_of_associated_jitt_events"];
        let name_array = res_array.split("|,| ");
        this.buildEventList(name_array);
      })
      .catch((error) => console.log("error", error));
  };

  buildEventList = (event_array) => {
    let element = this.eventsTarget;
    console.log("Element:", element);
    if (!event_array.length > 0 || event_array[0] == "") {
      let empty_notice = document.createElement("h5");
      let empty_text = document.createTextNode(
        "There are no events for this objective"
      );
      empty_notice.appendChild(empty_text);
      element.appendChild(empty_notice);
    } else {
      let ul = document.createElement("ul");
      ul.classList.add("list-group");
      for (let i = 0; i < event_array.length; i++) {
        let event = event_array[i];
        console.log("event array: ", event_array);
        console.log("event: ", event);
        let item = document.createElement("li");
        item.classList.add("list-group-item");
        let list_text = document.createTextNode(event);
        item.appendChild(list_text);
        console.log("item: ", item);
        ul.appendChild(item);
      }
      element.appendChild(ul);
    }
  };
}
