import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["indicator", "end", "start", "destroy"];
  connect() {
    this.changed("", true);
  }

  changed = (e, from_initial) => {
    let end_date = Date.parse(this.endTarget.value);
    let end_date_array = this.getEndDateArray();
    let start_date_array = this.getStartDateArray();
    let start_date = Date.parse(this.startTarget.value);
    let valid = false;
    if (from_initial) {
      valid = true;
    } else {
      valid = this.checkStartDateValidity(
        start_date,
        end_date_array,
        start_date_array,
        end_date
      );
    }
    console.log("VALID", valid);
    let today = Date.parse(Date());
    let indicator = this.indicatorTarget;
    if (!valid) {
      alert(
        "You cannot create an enrollment where the start date is before the end of another residency, or if another residency does not have an end date."
      );

      e.target.value = "";
      return;
    }
    if (end_date < today) {
      indicator.innerHTML = "Completed";
      indicator.className = "indicator indicator-completed";
    } else if (start_date > today) {
      indicator.innerHTML = "Not started yet";
      indicator.className = "indicator indicator-not-yet";
    } else if (
      (start_date < today && end_date > today) ||
      (start_date < today && isNaN(end_date))
    ) {
      indicator.innerHTML = "Current";
      indicator.className = "indicator indicator-current";
    }
  };

  getEndDateArray = () => {
    let elems = Array.from(document.getElementsByClassName("end-date"));
    let values = elems.map((e) => Date.parse(e.value));
    return values;
  };
  getStartDateArray = () => {
    let elems = Array.from(document.getElementsByClassName("start-date"));
    let values = elems.map((e) => Date.parse(e.value));
    return values;
  };

  checkStartDateValidity = (
    start_date,
    end_date_array,
    start_date_array,
    end_date
  ) => {
    let filtered_end_date_array = end_date_array.filter((e) => {
      return e != end_date;
    });
    console.log("END ARRAY", end_date_array);
    console.log("Start date", start_date);
    let invalids = filtered_end_date_array.filter((e) => {
      return start_date < e;
    });
    let end_blanks = end_date_array.filter((e) => {
      return isNaN(e);
    });
    let start_blanks = start_date_array.filter((e) => {
      return isNaN(e);
    });
    console.log("END BLANKS", end_blanks.length);
    console.log("START BLANKS", start_blanks.length);
    console.log("invalids", invalids.length);
    if (invalids.length < 1 && end_blanks.length <= start_blanks.length + 1) {
      return true;
    } else {
      return false;
    }
  };
  delete = (e) => {
    let hidden_field = this.destroyTarget;
    hidden_field.value = true;
    console.log("event", e.target);
    let wrapper = e.target.closest(".residency-row");
    wrapper.classList.add("hidden");
    console.log("DESTROY");
  };
}
