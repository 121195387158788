import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fileInput", "customButton"];

  connect() {
    this.updateButtonText(); // Set initial text

    this.fileInputTarget.addEventListener("change", () => {
      this.updateButtonText();
    });
  }

  updateButtonText() {
    const fileName = this.fileInputTarget.files[0]?.name || "Select a File";
    this.customButtonTarget.textContent = fileName;
  }
}
