import { Controller } from "stimulus";

export default class extends Controller {
  buttonPress(e) {
    const case_array = JSON.parse(e.target.dataset.cases);
    const cases_list = document.getElementById("cases-list");
    var inner = cases_list.innerHTML;
    inner = inner.trim ? inner.trim() : inner.replace(/^\s+/, "");

    if (inner.length == 0) {
      this.getCases(case_array);
    }
  }

  findExisting = (case_array, case_id) => {
    for (var i = 0; i < case_array.length; i++) {
      if (case_array[i].case_id == case_id) {
        return case_array[i].id;
      }
    }
  };

  checkboxToggle = (e) => {
    const hidden_delete =
      e.target.nextSibling.nextSibling.nextSibling.nextSibling;
    if (e.target == false) {
      hidden_delete.value = true;
    } else {
      hidden_delete.value = true;
    }
  };

  deleteAssociatedCases = (e) => {
    const cases_list = document.getElementById("cases-list");
    cases_list.innerHTML = "";
    const modal_button = document.getElementById("modal-button");
    modal_button.classList.remove("inactive-btn-theme");
    modal_button.classList.add("btn-theme");
    console.log(e.target.dataset.loid);
    const learning_objective = e.target.dataset.loid;

    fetch(`/delete_associated_cases/${learning_objective}.json`)
      .then((response) => {
        response.json;
      })
      .then((data) => {
        console.log(data);
      });
  };

  toggleSelectAll = (e) => {
    const status = e.target.dataset.status;
    e.target.removeAttribute("data-status");
    e.target.innerHTML = "";
    const all_checkboxes = document.getElementsByClassName("checkbox-square");
    if (status == "unchecked") {
      e.target.setAttribute("data-status", "checked");
      e.target.innerHTML = "Deselect All";
      for (var j = 0; j < all_checkboxes.length; j++) {
        const item = all_checkboxes[j];
        item.checked = true;
      }
    } else {
      e.target.setAttribute("data-status", "unchecked");

      e.target.innerHTML = "Select All";
      for (var j = 0; j < all_checkboxes.length; j++) {
        const item = all_checkboxes[j];
        console.log(item);
        item.checked = false;
      }
    }
  };

  getCases = (case_array) => {
    const cases_list = document.getElementById("cases-list");
    cases_list.innerHTML = "";
    const category_selector = document.getElementById("lo-category").value;
    fetch(`/by_category/${category_selector}.json`)
      .then((response) => response.json())
      .then((json) => {
        for (var j = 0; j < json.length; j++) {
          let random_number = Math.floor(Math.random() * 9000000) + 1000000;
          let item = json[j];
          const existing_id = this.findExisting(case_array, item.case.id);

          // name="simulation[simulations_medications_attributes][1588726706422][medication_id]"

          let label = document.createElement("LABEL");
          let div = document.createElement("div");
          div.setAttribute("class", "checkbox_item");
          label.setAttribute(
            "for",
            `learning_objective[cases_learning_objectives_attributes][${random_number}][case_id]`
          );
          let line_item = document.createElement("INPUT");
          line_item.setAttribute("type", "checkbox");
          line_item.setAttribute(
            "id",
            `learning_objective[cases_learning_objectives_attributes][${random_number}][case_id]`
          );
          line_item.setAttribute("class", "checkbox-square");
          line_item.setAttribute(
            "name",
            `learning_objective[cases_learning_objectives_attributes][${random_number}][case_id]`
          );
          line_item.setAttribute("value", `${item.case.id}`);
          let text = document.createTextNode(item.case.name);
          let line_break = document.createElement("br");
          label.appendChild(text);
          div.appendChild(line_item);
          div.appendChild(label);
          div.appendChild(line_break);

          if (existing_id != undefined) {
            line_item.setAttribute("checked", true);
            line_item.setAttribute(
              "data-action",
              "click->modal#checkboxToggle"
            );
            const hidden_id = document.createElement("INPUT");
            hidden_id.setAttribute("type", "Hidden");
            hidden_id.setAttribute("value", existing_id);
            hidden_id.setAttribute(
              "id",
              `learning_objective[cases_learning_objectives_attributes][${random_number}][id]`
            );
            hidden_id.setAttribute(
              "name",
              `learning_objective[cases_learning_objectives_attributes][${random_number}][id]`
            );
            div.appendChild(hidden_id);
            const delete_field = document.createElement("INPUT");
            delete_field.setAttribute("type", "Hidden");
            delete_field.setAttribute("value", false);
            delete_field.setAttribute(
              "id",
              `learning_objective[cases_learning_objectives_attributes][${random_number}][_destroy]`
            );
            delete_field.setAttribute(
              "name",
              `learning_objective[cases_learning_objectives_attributes][${random_number}][_destroy]`
            );
            div.appendChild(delete_field);
          }
          cases_list.appendChild(div);
        }
      });
  };
}
