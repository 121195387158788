import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["score", "row"];
  connect() {
    let score = this.scoreTarget.value;
    let row = this.rowTarget;
    this.setActiveSelector(row, score);
  }
  score(e) {
    let parent = e.target.closest(".eval-sect");
    let score = parent.dataset.score;
    this.scoreTarget.value = score;
    let row = this.rowTarget;
    this.setActiveSelector(row, score);
  }

  setActiveSelector(row, score) {
    let sections = row.getElementsByClassName("eval-sect");
    for (var i = 0; i < sections.length; i++) {
      let section = sections[i];
      if (section.dataset.score === score) {
        section.className = "col-md-2 eval-score-select-active eval-sect";
      } else {
        console.log("NOT THIS ONE", section);
        section.className = "col-md-2 eval-score-select eval-sect";
      }
    }
  }
}
