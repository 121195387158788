import {
    Controller
} from "stimulus"

export default class extends Controller {


    onTopSelect = (e) => {
        const val = e.target.value
        const input_target = e.target.nextSibling.nextSibling
        input_target.setAttribute("name", `q[scaffold_level_top${val}]`)
        input_target.setAttribute("id", `q_scaffold_level_top${val}`)
        console.log(input_target)
    }

    onBottomSelect = (e) => {
        const val = e.target.value
        const input_target = e.target.nextSibling.nextSibling
        input_target.setAttribute("name", `q[scaffold_level_bottom${val}]`)
        input_target.setAttribute("id", `q_scaffold_level_bottom${val}`)
        console.log(input_target)
    }
}