import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("CONNECTED", navigator.usb);
    navigator.usb.addEventListener("connect", (event) => {});
  }
  getDevices() {
    OpenPicker();
  }
}
const OpenPicker = async () => {
  const directoryHandle = await showDirectoryPicker({
    startIn: "documents",
  });
  const fileHandle = await self.showOpenFilePicker({
    startIn: directoryHandle,
    types: [
      {
        description: "Video Files",
        accept: {
          "video/avi": [".avi"],
        },
      },
    ],
  });
};
