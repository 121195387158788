import { Controller } from "stimulus";
import { get, set } from "./idb";

export default class extends Controller {
  static targets = ["input", "submit"];
  activeUploads = 0; // Tracks the number of active uploads
  saveForm = () => {
    let unsaved_videos = Array.from(
      document.getElementsByClassName("vid-link")
    );
    console.log("UNSAVED VIDS", unsaved_videos);
    if (unsaved_videos.length === 0) {
      console.log("NO UNSAVED");
      this.saveAndDeleteFolder();
    } else if (unsaved_videos.length === 1) {
      console.log("One unsaved");
      if (
        confirm(
          `** WARNING ** \nThis video has not been uploaded and will be deleted:\n${unsaved_videos.map(
            (v) => `${v.innerText.replace("CLICK TO UPLOAD", "")}\n`
          )}`
        )
      ) {
        this.activeUploads -= 1; // Decrement the active uploads counter
        console.log("Active uploads", this.activeUploads);
        if (this.activeUploads === 0) {
          alert("All uploads complete!");
          this.saveAndDeleteFolder();
        }
      } else {
        return;
      }
    } else if (unsaved_videos.length > 1) {
      if (
        confirm(
          `** WARNING ** \nThese videos have not been uploaded and will be deleted:\n${unsaved_videos.map(
            (v) => `${v.innerText.replace("CLICK TO UPLOAD", "")}\n`
          )}`
        )
      ) {
        this.activeUploads -= 1; // Decrement the active uploads counter
        console.log("Active uploads", this.activeUploads);
        if (this.activeUploads === 0) {
          alert("All uploads complete!");
          this.saveAndDeleteFolder();
        }
      } else {
        return;
      }
    }
  };

  saveAndDeleteFolder = async () => {
    //const parent_folder = await get("cmac_folder");
    const parent_folder = dirHandle;
    console.log("PARENT", parent_folder);
    const open_folder = await get("open_folder");
    console.log("OPEN", open_folder);
    await parent_folder.removeEntry(open_folder.name, { recursive: true });
    this.submitTarget.diabled = false;
    this.submitTarget.classList.remove("disabled");
    //document.getElementById("new_intubation_review").submit();
  };

  getusb = async () => {
    let patient_number_field = document.getElementById(
      "intubation_review_patient_number"
    );
    if (patient_number_field.value === "") {
      alert("Please enter a patient number");
      return;
    }
    dirHandle = await window.showDirectoryPicker();
    const permissionStatus = await dirHandle.queryPermission({
      mode: "readwrite",
    });

    if (permissionStatus === "prompt") {
      // Display a message to the user explaining that they will need to grant permission
      alert(
        "You will need to grant permission for this app to modify files in the selected directory."
      );
      // Now perform an action that requires permission to trigger the permission prompt
      await dirHandle.removeEntry("dummy", { recursive: true }).catch(() => {});
    }

    console.log("***DIR HANDLE NEW- really new***", dirHandle);
    if (dirHandle.name.match(/C-MAC/) || dirHandle.name === "\\") {
      this.createList(dirHandle);
      await set("cmac_folder", dirHandle);
    } else {
      alert("Please select the plugged in C-MAC device named 'C-MAC PM'");
    }
  };

  selected = async (e) => {
    if (e.target.closest("li").dataset.handle.split(".").pop() !== "avi") {
      console.log("SELECTED", e.target.dataset.handle);
      const directoryHandle = await get(e.target.closest("li").dataset.handle);
      this.createList(directoryHandle);
    } else {
      this.uploadFile(e);
    }
  };

  submitForm = async () => {
    console.log("SUBMITTING");
    document.getElementById("new_intubation_review").submit();
  };

  createList = async (directoryHandle) => {
    let file_list = document.getElementById("file-list");
    let page_1 = document
      .getElementById("upload-screen-1")
      .classList.add("hidden");
    let page_2 = document
      .getElementById("upload-screen-2")
      .classList.remove("hidden");
    file_list.innerHTML = "";
    this.setOpen(directoryHandle);
    for await (const [key, value] of directoryHandle.entries()) {
      if (key[0] !== "." && key !== "System Volume Information") {
        console.log({ key, value });

        let li = document.createElement("li");
        li.dataset.handle = key;
        li.dataset.action = "click->usb#selected";
        li.className =
          key.split(".").pop() === "avi" || value.kind == "directory"
            ? "list-group-item vid-link"
            : "list-group-item text-muted";
        let row = document.createElement("div");
        let col_1 = document.createElement("div");
        col_1.className = "col";
        let col_2 = document.createElement("div");
        col_2.className = "col align-self-end";
        row.className = "row";
        let text = document.createTextNode(` ${key}`);
        let icon = this.getIcon(key, value);
        let btn = this.getButton(key);

        col_1.appendChild(icon);
        col_1.appendChild(text);
        row.appendChild(col_1);
        row.appendChild(col_2);
        li.appendChild(row);
        if (btn) {
          col_2.appendChild(btn);
        }
        file_list.appendChild(li);
        await set(key, value);
        // if (key.includes(".avi")) {
        //   let file = await value.getFile();
        //   console.log("VIDEO FILE", file);
        //   dirHandle.removeEntry(key);
        // }
      }
      document.getElementById("open-picker-button").style.display = "none";
      document.getElementById("open-folder").style.display = "";

      //document.getElementById("directions").innerHTML =
      //"Navigate to the folder that contains your video file(s) and click on them to upload.";
    }
  };

  setOpen = async (directoryHandle) => {
    let open = await get("open_folder");
    await set("previous_folder", open);
    console.log("DH", directoryHandle);
    await set("open_folder", directoryHandle);
    let back_el = document.getElementById("open-folder");
    let icon = document.createElement("i");
    icon.className = "fa fa-folder-open";
    let text = document.createTextNode(` ${directoryHandle.name}`);
    back_el.innerHTML = "";
    back_el.appendChild(icon);
    back_el.appendChild(text);
  };

  goBack = async () => {
    let directoryHandle = await get("previous_folder");
    await this.createList(directoryHandle);
  };

  getButton = (key) => {
    if (key.split(".").pop() === "avi") {
      let upload_button = document.createElement("div");
      upload_button.className = "btn btn-sm btn-primary";
      let btn_text = document.createTextNode("CLICK TO UPLOAD");
      upload_button.appendChild(btn_text);
      return upload_button;
    }
  };

  getIcon = (key, value) => {
    let icon = document.createElement("i");
    if (value.kind === "directory") {
      icon.className = "fa fa-folder";
    } else if (key.split(".").pop() === "avi") {
      icon.className = "fa fa-film";
    } else {
      icon.className = "fa fa-file";
    }
    return icon;
  };

uploadFile = async (e) => {
  this.activeUploads += 1;
  e.target.closest("li").dataset.action = "";
  let btn = e.target.closest("li").getElementsByClassName("btn")[0];

  let progress_bar = document.createElement("div");
  progress_bar.className = "progress";
  let bar_indicator = document.createElement("div");
  bar_indicator.className = "progress-bar progress-bar-striped bg-info";
  bar_indicator.id = e.target.closest("li").dataset.handle;

  bar_indicator.setAttribute("role", "progressbar");
  bar_indicator.style.width = "0%";
  progress_bar.appendChild(bar_indicator);
  e.target.closest("li").appendChild(progress_bar);
  btn.className = "hidden";

  let file_handle = await get(e.target.closest("li").dataset.handle);
  const file = await file_handle.getFile();
  const review_id = this.inputTarget.getAttribute("data-review-id");
  let video_association_id = document.getElementsByClassName("video-association-id")[0].value;
  console.log("INPUT TARGET NEW", this.inputTarget);
  console.log("VIDEO ASSOCIATION ID", video_association_id);

  if (file.size <= 5 * 1024 * 1024) {
    // Direct upload for files smaller than or equal to 5MB
    await this.directUpload(file, video_association_id, review_id);
     bar_indicator.style.width = `100%`;
  } else {
    // Multipart upload for files larger than 5MB
    const uuid = crypto.randomUUID(); // Generate a UUID for the upload session

    const chunkSize = 5 * 1024 * 1024; // 5MB chunks
    const totalChunks = Math.ceil(file.size / chunkSize); // Calculate the total number of chunks
    let filename = file.name;
    console.log("File Name", file.name);
    const uploadId = await this.initiateMultipartUpload(uuid, video_association_id, review_id, filename);
    console.log("Upload ID:", uploadId); // Log the upload ID
    if (!uploadId) {
      alert("Failed to initiate multipart upload.");
      return;
    }

    let uploadedParts = [];

    for (let index = 0; index < totalChunks; index++) {
      const start = index * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);

      const part = await this.uploadPart(chunk, index + 1, uuid, video_association_id, uploadId, filename);
      if (!part.etag) {
        alert(`Failed to upload part ${index + 1}`);
        return;
      }

      uploadedParts.push({ etag: part.etag, part_number: index + 1 });

      // Update the progress bar
      bar_indicator.style.width = `${((index + 1) / totalChunks) * 100}%`;
    }

    const completed = await this.completeMultipartUpload(uuid, video_association_id, uploadId, uploadedParts, filename);
    if (!completed) {
      alert("Failed to complete multipart upload.");
      return;
    }
  }

  this.activeUploads -= 1;
  if (this.activeUploads === 0) {
    alert("All uploads complete!");
    this.saveAndDeleteFolder();
  }
};

directUpload = async (file, video_association_id, review_id) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('videoAssociationId', video_association_id);
  formData.append('reviewId', review_id);
  formData.append('filename', file.name);

  const response = await fetch('/uploads/direct_upload', {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    console.error("Direct upload failed:", await response.text());
    return null;
  }

  const data = await response.json();
  console.log("Direct upload completed with response: ", data);
  return data;
};

initiateMultipartUpload = async (uuid, video_association_id, review_id, filename) => {
  const response = await fetch('/uploads/initiate_multipart_upload', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uuid, video_association_id, review_id, filename }),
  });

  if (!response.ok) {
    console.error("Failed to initiate multipart upload:", await response.text());
    return null;
  }

  const data = await response.json();
  console.log("Initiated upload ID: ", data.uploadId); // Verify the upload ID
  return data.uploadId;
};

uploadPart = async (chunk, partNumber, uuid, video_association_id, uploadId, filename) => {
  const formData = new FormData();
  formData.append('chunk', chunk);
  formData.append('partNumber', partNumber);
  formData.append('uuid', uuid);
  formData.append('videoAssociationId', video_association_id);
  formData.append('uploadId', uploadId);
  formData.append('filename', filename);

  console.log(`Uploading part ${partNumber} with upload ID: ${uploadId}`); // Log the part upload

  const response = await fetch('/uploads/upload_part', {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    console.error(`Failed to upload part ${partNumber}:`, await response.text());
    return { etag: null };
  }

  const data = await response.json();
  return data;
};

completeMultipartUpload = async (uuid, video_association_id, uploadId, uploadedParts, filename) => {
  const response = await fetch('/uploads/complete_multipart_upload', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uuid,
      video_association_id,
      uploadId,
      filename,
      parts: uploadedParts,
    }),
  });

  if (!response.ok) {
    console.error("Failed to complete multipart upload:", await response.text());
    return false;
  }

  const data = await response.json();
  return data.status === 'completed';
};

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }
  // const filters = [{}];
  // navigator.usb.requestDevice({ filters: filters }).then((devices) => {
  //   console.log("DEVICES", devices);
  // });
}


async function sendChunk(
  chunk,
  index,
  totalChunks,
  file,
  progressCallback,
  review_id,
  uuid,
  video_association_id
) {
  console.log("SENDING CHUNK VIDO ASS UUID", video_association_id);
  const formData = new FormData();
  formData.append("chunk", new Blob([chunk]), "chunk");
  formData.append("index", index);
  formData.append("totalChunks", totalChunks);
  formData.append("fileName", file.name);
  formData.append("reviewId", review_id);
  formData.append("uuid", uuid);
  formData.append("videoAssociationId", video_association_id);
  const response = await fetch("/uploads", {
    method: "POST",
    body: formData,
    headers: {
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
  });
  console.log("RESPONSE", response);
  if (!response.ok) {
    console.log("NOT OK");
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  // parse the JSON response body
  let data = {};
  try {
    data = await response.json();
    console.log("DATA", data);
    setVideoUuid(data.key);
  } catch (error) {
    console.error("Error parsing response as JSON:", error);
  }
  // Update the progress
  progressCallback(chunk.byteLength);
}
function generateUUID() {
  return "xxxxxxxxxxxx4xxxyxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
function setVideoUuid(uuid) {
  let video_uuid = document.getElementsByClassName("video-uuid")[0];
  
  if (video_uuid.value === "") {
    video_uuid.value = uuid;
  } else {
    video_uuid.value += "|" + uuid;
  }
}

function uploadComplete() {
  //alert("Upload complete!");
}
function readFileInChunks(file, progressCallback, completeCallback, review_id, video_association_id) {
  const chunkSize = 1024 * 1024 * 100; // 4MB
  const pieceSize = 1024 * 1024; // 1MB
  let offset = 0;
  let index = 0;
  let totalUploaded = 0;
  const totalChunks = Math.ceil(file.size / pieceSize);
  const uuid = generateUUID();
  console.log("VIDEO ASSOCIATION ID", video_association_id);
  function readChunk() {
    const reader = new FileReader();
    const blob = file.slice(offset, offset + chunkSize);
    reader.onload = (event) => {
      // Divide the chunk into pieces
      let chunkOffset = 0;
      function sendPiece() {
        console.log(
          "offset is",
          chunkOffset,
          "file size is",
          event.target.result.byteLength
        );
        if (chunkOffset < event.target.result.byteLength) {
          const piece = event.target.result.slice(
            chunkOffset,
            chunkOffset + pieceSize
          );
          sendChunk(
            piece,
            index,
            totalChunks,
            file,
            (uploaded) => {
              totalUploaded += uploaded;
              progressCallback(totalUploaded / file.size);
              chunkOffset += pieceSize;
              index += 1;
              sendPiece();
            },
            review_id,
            uuid,
            video_association_id
          );
        } else {
          // All pieces of this chunk have been sent
          console.log(
            "final offset is",
            offset,
            "final file size is",
            file.size
          );
          offset += chunkSize;
          if (offset < file.size) {
            readChunk();
          } else {
            completeCallback();
          }
        }
      }
      sendPiece();
    };
    reader.readAsArrayBuffer(blob);
  }

  readChunk();
}

let dirHandle = null;
