import { Controller } from "stimulus";
import "selectize";

export default class extends Controller {
  static targets = ["tokenize"];
  connect() {
    var myModal = new bootstrap.Modal(
      document.getElementById("category-modal")
    );
    $(this.tokenizeTarget).selectize({
      create: function (input, callback) {
        var sent = false;

        myModal.show();
        $('input[type="submit"]').attr("disabled", false);
        $("#new-tag-form").trigger("reset");
        $("#teaching_resource_new_tag").val(input);
        $("#new-tag-form").on("submit", function (e) {
          e.preventDefault();
          if (sent === false) {
            let input_value = $("#teaching_resource_new_tag").val();
            let data = JSON.stringify({ name: input_value });
            console.log("SENDING", data);
            $.ajax({
              url: "/new_tags.json",
              method: "POST",
              data: data,
              contentType: "application/json",
              success: function (response) {
                console.log("SUCCESS!!!!");
                sent = true;
                callback({ value: response.name, text: response.name });
                myModal.hide();
                $("#tokenize-selectized").trigger("focus");
              },
            });
          }
        });
      },
    });
  }
}
