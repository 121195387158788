//Create a stiumuls controller
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "checkbox"];
  connect() {
    let timestamp = this.element.dataset.shouldTimestamp;
    console.log("connected to timestamp controller", timestamp);
  }

  selected(event) {
    let timestamp = this.element.dataset.shouldTimestamp;
    let action_string = this.element.dataset.actionString;
    let selected_user = event.target.value;
    // fetch user name
    let url = "/users_admin/" + selected_user + ".json";
    let post_string = "";
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        let user_name = `${data.last_name}, ${data.first_name}`;
        let user_id = data.id;
        post_string = action_string + " " + user_name;
        this.element.dataset.actionUserId = user_id;
        console.log("action string", post_string);
        console.log("Selected timestamp", timestamp);
        if (timestamp == "true") {
          // add a record to the cqi_report_timestamps table
          let url = "/cqi_report_timestamps";
          let post_data = {
            cqi_report_timestamp: {
              report_item_id: this.element.dataset.itemId,
              action: post_string,
              user_id: data.id,
            },
          };
          console.log("data to post", post_data);
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
                .content,
            },
            body: JSON.stringify(post_data),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("data", data);
            });
        }
      });
  }
}
