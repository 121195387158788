import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const domain_array = domain_hash();
    let current_domain = this.element.dataset.epaDomain;
    let array_record = domain_array.find((rec) => {
      return rec.short === current_domain;
    });
    let current_index = domain_array.indexOf(array_record);
    let next_record = domain_array[current_index < 6 ? current_index + 1 : 6];
    let previous_record =
      domain_array[current_index > 0 ? current_index - 1 : 0];
    this.setTitle();
    this.filterRecords();
    this.filterRecordsbyEpa();
    this.setDescription();
  }

  goForward() {
    const domain_array = domain_hash();
    let current_domain = this.element.dataset.epaDomain;
    let array_record = domain_array.find((rec) => {
      return rec.short === current_domain;
    });
    let current_index = domain_array.indexOf(array_record);
    let next_record = domain_array[current_index < 6 ? current_index + 1 : 6];
    this.element.dataset.epaDomain = next_record.short;
    this.setTitle();
    this.filterRecords();
    this.setDescription();
  }
  goBack() {
    const domain_array = domain_hash();
    let current_domain = this.element.dataset.epaDomain;
    let array_record = domain_array.find((rec) => {
      return rec.short === current_domain;
    });
    let current_index = domain_array.indexOf(array_record);

    let previous_record =
      domain_array[current_index > 0 ? current_index - 1 : 0];
    this.element.dataset.epaDomain = previous_record.short;
    this.setTitle();
    this.filterRecords();
    this.setDescription();
  }
  setTitle() {
    const domain_array = domain_hash();
    let current_domain = this.element.dataset.epaDomain;
    let array_record = domain_array.find((rec) => {
      return rec.short === current_domain;
    });
    document.getElementById("domain-name").innerHTML = array_record.long;
  }
  filterRecords() {
    let items = document.getElementsByClassName("domain-list-item");
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      console.log("EPAS", item.dataset.epas);
      if (
        item.dataset.domain.toLowerCase() !== this.element.dataset.epaDomain
      ) {
        item.classList.add("hidden");
      } else {
        item.classList.remove("hidden");
      }
    }
  }
  filterRecordsbyEpa() {
    let items = document.getElementsByClassName("domain-list-item");
    console.log("check array", items);
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      let epa_array = JSON.parse(item.dataset.epas);
      console.log("explore json", epa_array[0][0]);
      let epa_in_array = epa_array.find((rec) => {
        return rec[0].toString() === this.element.dataset.epa;
      });
      let epa_is_in_array = epa_in_array !== undefined;
      if (epa_is_in_array) {
        item.classList.remove("epa-hide");
      } else {
        item.classList.add("epa-hide");
      }
    }
    this.element.dataset.epaFilter = "true";
    this.setFilterStatement();
    this.setDescription();
  }
  removeEpaFilter() {
    let items = document.getElementsByClassName("domain-list-item");
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      item.classList.remove("epa-hide");
    }
    this.element.dataset.epaFilter = "false";
    this.setFilterStatement();
    this.setDescription();
  }
  setFilterStatement() {
    let filter_state = this.element.dataset.epaFilter;
    if (filter_state === "true") {
      document.getElementById("show-all-link").classList.remove("hidden");
      document.getElementById("refilter-link").classList.add("hidden");
    } else {
      document.getElementById("show-all-link").classList.add("hidden");
      document.getElementById("refilter-link").classList.remove("hidden");
    }
  }
  setDescription() {
    let unfiltered = document.querySelectorAll(
      ".domain-list-item:not(.hidden):not(.epa-hide)"
    );

    let sum = 0.0;
    for (var i = 0; i < unfiltered.length; i++) {
      let item = unfiltered[i];
      let value = parseFloat(
        item.getElementsByClassName("epa-domain-value")[0].innerHTML
      );
      sum += value;
    }
    let avg = Number((sum / unfiltered.length).toFixed(1));
    document.getElementById("domain-average").innerHTML = avg > 0 ? avg : "N/A";

    let epa_name = this.element.dataset.epaName;
    let filter_state = this.element.dataset.epaFilter;
    let suffix = filter_state === "true" ? epa_name : "All ratings";
    let span = document.createElement("span");
    let number = unfiltered.length;
    document.getElementById(
      "epa-domain-report-description"
    ).innerHTML = `Based on <span class="epa-ratings-count"> ${number} </span> ratings evaluating <br /> <span class="epa-ratings-count"> ${suffix} </span>`;
  }
}
const domain_hash = () => {
  let myarray = [];
  myarray[0] = { short: "sa", long: "Situation Awareness" };
  myarray[1] = { short: "hg", long: "History Gathering" };
  myarray[2] = { short: "pa", long: "Patient Assessment" };
  myarray[3] = { short: "dm", long: "Decision Making" };
  myarray[4] = { short: "co", long: "Communication" };
  myarray[5] = { short: "ru", long: "Resource Utilization" };
  myarray[6] = { short: "ps", long: "Procedural Skills" };
  return myarray;
};
