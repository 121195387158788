import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("Hello, Learning Objective", this.data.get("id"));
    let list_item = document.getElementById(`objective${this.data.get("id")}`);
    console.log("list item", list_item);
    let resource = this.data.get("id");
    let cohort_members = JSON.parse(this.data.get("cohort"));
    console.log("cohort members: ", cohort_members);

    Promise.all(
      cohort_members.map((item) => GetCompleted(item, resource))
    ).then((array) => {
      console.log("Here is the new arrray ", array);
    });
  }
}

const GetCompleted = (user, resource) => {
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Basic ZDJhYmQ0NmI1MmMyMmE5ZGNiYWIyOWFkODU0NWU3ZmRiYjY4MWViODozNzYwZDQ2ZTQ2ODhiYzYxNjRiODU2NjA0MjkzMThlNDBmM2FlY2Iy"
  );
  myHeaders.append("X-Experience-API-Version", "1.0.1");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  let agent = {};
  agent.mbox = user;
  let url_agent = encodeURIComponent(JSON.stringify(agent));
  console.log("agent: ", url_agent);
  let url = `https://orngelearninglocker.com/data/xAPI/statements?activity=https://basehospitaladmin.com/learning_objectives/${resource}.json&agent=${url_agent}&related_activities=true`;
  console.log(url);
  fetch(url, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      console.log("json: ", json);
      return json;
    })
    .catch((error) => console.log("error", error));
};
