import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["objectiveTypeSelector", "epaFields"];
  connect() {
    this.objective();
  }
  objective() {
    let epa_fields = this.epaFieldsTarget;
    console.log("EPA FIELDS", epa_fields);
    let selected = this.objectiveTypeSelectorTarget.value;
    if (selected == "EPA" || selected == "Competency") {
      epa_fields.classList.remove("hidden");
    } else {
      epa_fields.classList.add("hidden");
    }
  }
}
