//create a stimuls controller for the default scale id
import { Controller } from "stimulus";

export default class extends Controller {
  //static targets = ["defaultScaleId"];

  connect() {}

  //when the user clicks on a scale, the scale id is set to the default scale id
  scaleSelect(event) {
    let selector_name = event.target.name;
    let target = "[rating_items_rating_scale_attributes][rating_scale_id]";
    let array_replacement = "[default_scale_item_id]";
    let array_parts = selector_name.split(target);
    let new_selector_name = array_parts.join(array_replacement);
    let selector_id = event.target.id;
    let replacement = "default_scale_item_id";
    let parts = selector_id.split("_");
    parts.splice(parts.length - 8, 8, replacement);
    let new_selector_id = parts.join("_");
    console.log("SELECTOR ID", selector_id);
    console.log("NEW ID", new_selector_id);
    document.getElementById(new_selector_id)?.remove();
    console.log("selected", event.target.value);
    let rating_scale_id = event.target.value;
    let url = "/rating_scales/" + rating_scale_id + ".json";
    console.log("url", url);
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        let select = document.createElement("select");

        select.setAttribute("name", new_selector_name);
        select.setAttribute("id", new_selector_id);
        select.classList.add("form-control");
        select.classList.add("mb-3");

        // Create default option
        let defaultOption = document.createElement("option");
        defaultOption.setAttribute("value", "");
        defaultOption.innerHTML = "Select default (if applicable)";
        select.appendChild(defaultOption);

        data["rating_scale_items"].forEach((item) => {
          let option = document.createElement("option");
          option.setAttribute("value", item.id);
          option.innerHTML = `${item.numerical_value || ""} | ${
            item.text_value || ""
          }`;
          select.appendChild(option);
        });
        //append the select to the form
        let form = this.element;
        form.appendChild(select);
      });
    //create selector with data['rating_scale_items'] as options
  }
}
