// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("Hello, Course Controller");
    const resource = this.data.get("resource");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic ZDJhYmQ0NmI1MmMyMmE5ZGNiYWIyOWFkODU0NWU3ZmRiYjY4MWViODozNzYwZDQ2ZTQ2ODhiYzYxNjRiODU2NjA0MjkzMThlNDBmM2FlY2Iy"
    );
    myHeaders.append("X-Experience-API-Version", "1.0.1");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    let agent = {};
    agent.mbox = "mailto:BCameron@ornge.ca";
    let url_agent = encodeURIComponent(JSON.stringify(agent));
    console.log("agent: ", url_agent);

    fetch(
      `https://orngelearninglocker.com/data/xAPI/statements?activity=https://basehospitaladmin.com${resource}.json&agent=${url_agent}&related_activities=true`,
      requestOptions
    )
      .then((response) => response.json())
      .then((json) => transformResult(json.statements))
      .catch((error) => console.log("error", error));
  }
}

const transformResult = (array) => {
  let result = [];
  console.log("Item: ", array);
  result = array.map((item) => {
    let object = {};
    object.actor = item.actor.name;
    object.object = item.object.definition.name["en-US"];
    object.verb = item.verb.display["en-US"];
    return object;
  });
  console.log(result);
};
