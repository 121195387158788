import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  static targets = ["spinner"];
  connect() {
    console.log("Hello, FielUpload");
  }

  uploading(event) {
    const files = event.dataTransfer.files;
    this.spinnerTarget.classList.remove("hidden");
  }
}
