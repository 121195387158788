import { Controller } from "stimulus";
let medic_array = [];
export default class extends Controller {
  connect() {
    let inputs = document.getElementsByClassName("fm-input");
    getMedics();
    for (let item of inputs) {
      let selector = item
        .closest(".cohort-section")
        .getElementsByClassName("medic-selector")[0];
      selector.value = item.value;
    }
  }
  medic_select(event) {
    let element = event.target;
    let selected_id = element.options[element.selectedIndex].value;
    let medic_record = medic_array.find((medic) => medic.fm_id === selected_id);
    let form_group = element.closest(".cohort-section");
    form_group.getElementsByClassName("name-input")[0].value =
      medic_record.name;
    form_group.getElementsByClassName("email-input")[0].value =
      medic_record.email;
    form_group.getElementsByClassName("fm-input")[0].value = medic_record.fm_id;
  }
}

const getMedics = () => {
  fetch("/get_medics")
    .then((response) => response.json())
    .then((json) => {
      medic_array = json;
    })
    .catch((error) => console.log("error", error));
};
