import {
    Controller
} from "stimulus";

export default class extends Controller {
    static targets = ["code"]

    getDocument = (id) => {
        this.getAuthCode();
        this.provideToken = (token) => {
            const access_token = token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${access_token}`);
            myHeaders.append("Accept", "application/vnd.mendeley-document.1+json");
            var requestOptions = {
                method: 'GET',
                headers: myHeaders

            };
            fetch(`https://api.mendeley.com/documents/${id}?view=client`, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    this.buildRefModalContent(json)
                }).catch(error => console.log('error', error));
        }
    }

    getDocuments = () => {
        this.getAuthCode();
        this.provideToken = (token) => {
            const access_token = token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${access_token}`);
            myHeaders.append("Accept", "application/vnd.mendeley-document.1+json");
            var requestOptions = {
                method: 'GET',
                headers: myHeaders

            };
            fetch("https://api.mendeley.com/documents?limit=500&view=client", requestOptions)
                .then(response => response.json())
                .then((json) => {
                    console.log(json);
                    this.buildArticleList(json)
                }).catch(error => console.log('error', error));
        }

    }

    buildArticleList = (json) => {
        const article_list = document.getElementById('article-list')
        for (var j = 0; j < json.length; j++) {
            const item = json[j];
            const list_item = document.createElement('li');
            const item_text = document.createTextNode(item.title)
            list_item.appendChild(item_text)
            list_item.classList.add("list-group-item")
            list_item.classList.add("article-title")
            if (item.authors) {
                const author_list = this.buildAuthors(item.authors)
                if (item.source) {
                    const source_span = document.createElement("span")
                    source_span.classList.add("journal-title")
                    const source = document.createTextNode(` in ${item.source} (${item.year})`)
                    source_span.appendChild(source)
                    author_list.appendChild(source_span)
                }
                list_item.appendChild(author_list)
            }
            if (item.abstract) {
                const popover = this.buildAbstractPopup(item.abstract, item.id)
                list_item.appendChild(popover)
            }
            list_item.appendChild(this.buildFormFields(item.title, item.id))
            article_list.appendChild(list_item)
        }
    }

    buildAuthors = (authors_array) => {
        if (authors_array != undefined) {
            const div_item = document.createElement('div');
            div_item.classList.add("author-list")
            for (var j = 0; j < authors_array.length; j++) {
                const author = authors_array[j];
                let item_text = ''
                if (j == 0) {
                    item_text = document.createTextNode(`${author.last_name}, ${author.first_name}`)
                } else {
                    item_text = document.createTextNode(` | ${author.last_name}, ${author.first_name}`)
                }
                div_item.appendChild(item_text)
            }
            return div_item
        } else {
            return ""
        }

    }

    buildFormFields = (name, id) => {
        let random_number = Math.floor(Math.random() * 9000000) + 1000000;
        const field_identifier = `learning_objective[learning_objective_references_attributes][${random_number}][reference_id]`
        const checkbox = document.createElement("INPUT")
        checkbox.setAttribute("type", "checkbox")
        checkbox.setAttribute("class", "checkbox-square")
        checkbox.setAttribute("name", field_identifier)
        checkbox.setAttribute("id", field_identifier)
        checkbox.setAttribute("value", id)
        const hidden_field_identifier = `learning_objective[learning_objective_references_attributes][${random_number}][reference_name]`
        const hidden_name_field = document.createElement("input")
        hidden_name_field.setAttribute("id", hidden_field_identifier)
        hidden_name_field.setAttribute("name", hidden_field_identifier)
        hidden_name_field.setAttribute("type", "hidden")
        hidden_name_field.setAttribute("value", name)
        const hidden_wrapper = document.createElement("div")
        hidden_wrapper.appendChild(checkbox)
        hidden_wrapper.appendChild(hidden_name_field)
        return hidden_wrapper
    }

    buildAbstractPopup = (info) => {
        let id = Math.floor(Math.random() * 9000000) + 1000000;
        const button_wrapper = document.createElement("div")
        button_wrapper.setAttribute("class", "pop-over-container")
        const more_button = document.createElement("button")
        more_button.setAttribute("type", "button")
        more_button.setAttribute("class", "btn btn-sm btn-info")
        more_button.setAttribute("data-toggle", "collapse")
        more_button.setAttribute("data-target", `#try${id}`)
        more_button.setAttribute("aria-expanded", "false")
        more_button.setAttribute("aria-controls", id)
        const more_div = document.createElement("div")
        more_div.setAttribute("class", "collapse")
        more_div.setAttribute("id", `try${id}`)
        const more_card = document.createElement("div")
        more_card.setAttribute("class", "card")
        more_card.classList.add("card-body")
        const more_text = document.createTextNode(info)
        more_card.appendChild(more_text)
        more_div.appendChild(more_card)
        const text = document.createTextNode("Abstract")
        more_button.appendChild(text)
        button_wrapper.appendChild(more_button)
        button_wrapper.appendChild(more_div)
        return button_wrapper
    }

    deleteRow = (e) => {
        const parent = e.target.closest("li")
        const delete_field = parent.getElementsByClassName("delete-field")[0]
        delete_field.setAttribute("value", 1)
        parent.classList.add("hidden")

    }
    /* <button type="button" class="btn btn-secondary" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
              Popover on right
            </button> */

    /* <button type="button" class="btn btn-secondary" data-container="body" data-toggle="popover" data-placement="left" data-content="Suggests ways on how to improve survey response rate. Reasons for conducting health education-related surveys; Importance of response rate; Principles for improving response rates; Discussion on a good response rate."></button> */

    connect() {
        if (this.codeTarget.dataset.code.length > 0) {
            this.exchangeAuthForAccess(this.codeTarget.dataset.code)
        } else {
            console.log("this does not have code")
        }
    }

    buttonPress = () => {
        console.log("pressing reference modal open")
        this.authorize()
    }

    populateRefDetails = (e) => {
        const id = e.target.dataset.id
        this.getDocument(id)

    }

    buildRefModalContent = (json) => {
        console.log(json)
        const modal_header = document.getElementById("refDetailModalLabel")
        const modal_body = document.getElementById("refDetailModalBody")
        modal_header.innerHTML = ""
        modal_body.innerHTML = ""

        const title = document.createTextNode(json.title)
        modal_header.appendChild(title)
        if (json.authors) {
            const author_list = this.buildAuthors(json.authors)
            modal_body.appendChild(author_list)
        }
        if (json.source) {
            const source_span = document.createElement("span")
            source_span.classList.add("journal-title")
            const source = document.createTextNode(` in ${json.source} (${json.year})`)
            source_span.appendChild(source)
            modal_body.appendChild(source_span)
        }
        if (json.abstract) {
            const abstract_div = document.createElement("div")
            abstract_div.setAttribute("class", "abstract-text")
            const text = document.createTextNode(json.abstract)
            abstract_div.appendChild(text)
            modal_body.appendChild(abstract_div)
        }
        if (json.file_attached) {
            const file_link = document.createElement("button")
            file_link.setAttribute("data-action", "click->references#getFiles")
            file_link.setAttribute("data-id", json.id)
            const btn_txt = document.createTextNode("Download File")
            file_link.appendChild(btn_txt)
            modal_body.appendChild(file_link)
        }
    }

    exchangeAuthForAccess = (code) => {
        const connected = document.getElementById('connected')
        const connecting = document.getElementById('connecting')
        const error = document.getElementById('error')
        console.log(connected)
        connecting.classList.remove('hide')
        connecting.classList.add('show')
        // This is client id and secret generated from https://dev.mendeley.com/myapps.html with a colon in between
        const auth_cred = btoa("8143:m2PGl6a2IIVyi4Uz")
        console.log(`auth: ${auth_cred}`)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Basic ${auth_cred}`);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("grant_type", "authorization_code");
        urlencoded.append("code", code);
        // urlencoded.append("redirect_uri", "http://localhost:3000/mendeley_auth");
        urlencoded.append("redirect_uri", "https://basehospitaladmin.com/mendeley_auth");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch("https://api.mendeley.com/oauth/token", requestOptions)
            .then(response => response.json())
            .then((json) => {
                this.postAccessToDatabase(json);
            }).catch(error => console.log('error', error));
    }

    postAccessToDatabase = (json) => {
        const connected = document.getElementById('connected')
        const connecting = document.getElementById('connecting')
        const error = document.getElementById('error')
        console.log(`access: ${json.access_token}`)
        fetch('mendeley_create.json', {
            method: 'post',
            body: JSON.stringify(json),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': Rails.csrfToken()
            },
            credentials: 'same-origin'
        }).then(function (response) {
            if (response.ok) {
                console.log('all good')
                connecting.classList.remove('show')
                connecting.classList.add('hide')
                connected.classList.remove('hide')
                connected.classList.add('show')
            } else {
                console.log("there was an error")
                connecting.classList.remove('show')
                connecting.classList.add('hide')
                error.classList.remove('hide')
                error.classList.add('show')

            }
        });

    }

    patchRefreshedToDatabse = (json) => {
        fetch('/mendeley.json', {
            method: 'PATCH',
            body: JSON.stringify(json),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': Rails.csrfToken()
            },
            credentials: 'same-origin'
        }).then(function (response) {
            if (response.ok) {
                console.log(`response from db: ${response}`)
                getAuthCode();

            } else {
                console.log("there was an error")

            }

        })

    }

    authorize = () => {
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        const url = "https://api.mendeley.com/oauth/authorize"
        const code = "response_type=code"
        const scope = "scope=all"
        const client = "client_id=8143"
        const redirect = `redirect_uri = $ {encodeURI("https://basehospitaladmin.com/mendeley_auth")}`
        window.open(`${url}?${client}&${redirect}&${code}&${scope}`)
    }

    authorize2 = () => {

        var requestOptions = {
            method: 'GET',

            redirect: 'follow'
        };

        fetch("https://api.mendeley.com/oauth/authorize?client_id=8084&redirect_uri=http://localhost:3000&response_type=code&scope=all", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    refreshToken = (refresh_token) => {
        const auth_cred = btoa("8143:m2PGl6a2IIVyi4Uz")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Basic ${auth_cred}`);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("grant_type", "refresh_token");
        urlencoded.append("refresh_token", refresh_token);
        urlencoded.append("redirect_uri", "http://basehospitaladmin.com/mendeley_auth");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch("https://api.mendeley.com/oauth/token", requestOptions)
            .then(response => response.json())
            .then((json) => {
                console.log(json)
                this.setToken(json.access_token)
                this.patchRefreshedToDatabse(json)
            }).catch(error => console.log('error', error));
    }

    getAuthCode = () => {
        fetch("/mendeley.json").then(response => response.json()).then(result => {
            const access_token = result.mendeley.access_token
            console.log(`access: ${access_token}`)
            const refresh_token = result.mendeley.refresh_token
            const now = Date.now()
            const expires = new Date(result.mendeley.token_expires).getTime()
            console.log(`now ${now} expires ${expires}`)
            if (now > expires) {
                this.refreshToken(refresh_token)
                this.setToken = (result) => {
                    console.log(`refreshed ${result}`)
                }
            } else {
                this.provideToken(access_token)
            }
        })
    }

    downloadFile = (json) => {
        this.getAuthCode();
        this.provideToken = (token) => {
            const access_token = token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${access_token}`);
            const proxyurl = "https://thawing-retreat-46673.herokuapp.com/"
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
            };
            fetch(`https://api.mendeley.com/files/${json[0].id}`, requestOptions).then(resp => {
                console.log(resp)
            })
        }
    }

    getFiles = (e) => {
        console.log("IN FUNCTION")
        e.preventDefault()
        const id = e.target.dataset.id
        console.log(`ref id: ${id}`)
        this.getAuthCode();
        this.provideToken = (token) => {
            const access_token = token;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${access_token}`);
            myHeaders.append("Accept", "application/vnd.mendeley-file.1+json");
            var requestOptions = {
                method: 'GET',
                headers: myHeaders

            };
            fetch(`https://api.mendeley.com/files?document_id=${id}`, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    console.log(`file id: ${json[0].id}`)
                    this.downloadFile(json)

                }).catch(error => console.log('error', error));
        }

    }
}