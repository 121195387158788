import { Controller } from "stimulus";
import "selectize";

export default class extends Controller {
  static targets = ["tokenize"];

  connect() {
    //document.querySelectorAll(".selectize-control").forEach((e) => e.remove());
    console.log("retured here");
    $(this.tokenizeTarget).selectize({
      create: false,
    });
  }
}
