import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    showCount();
    buildSelector();
    setButtonState();
    setCompletionText();
    updatePdfLink();
  }
  showAll() {
    let items = document.getElementsByClassName("medic-list-item");
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      let status =
        item.getElementsByClassName("completion-status")[0].innerHTML;
      item.classList.remove("hidden");
    }
    showCount();
    setState("filter", "all");
    setState("base", "none");
    setButtonState();
    document.getElementById("mySelect").options.selectedIndex = -1;
    let completed_count = document.getElementsByClassName("completed").length;
    let incomplete_count =
      document.getElementsByClassName("not_completed").length;
    updatePie(completed_count, incomplete_count);
    setCompletionText();
    updatePdfLink();
  }
  incomplete() {
    let base_filter = document.getElementById("state").dataset.baseSelected;
    let items = [];
    if (base_filter != "none") {
      items = document.querySelectorAll(
        `.medic-list-item[data-base='${base_filter}']`
      );
    } else {
      items = document.getElementsByClassName("medic-list-item");
    }
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      let status = item.dataset.completed;
      console.log("STATUS", status);
      if (status === "completed") {
        item.classList.add("hidden");
      } else {
        item.classList.remove("hidden");
      }
    }
    showCount();
    setState("filter", "incomplete");
    setButtonState();
    updatePdfLink();
  }
  complete() {
    let base_filter = document.getElementById("state").dataset.baseSelected;
    let items = [];
    if (base_filter != "none") {
      items = document.querySelectorAll(
        `.medic-list-item[data-base='${base_filter}']`
      );
    } else {
      items = document.getElementsByClassName("medic-list-item");
    }

    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      let status =
        item.getElementsByClassName("completion-status")[0].innerHTML;
      if (status.trim() === "Not Completed") {
        item.classList.add("hidden");
      } else {
        console.log("UNHIDE");
        item.classList.remove("hidden");
      }
    }
    showCount();
    setState("filter", "completed");
    setButtonState();
    updatePdfLink();
  }
  all() {
    let items = document.getElementsByClassName("medic-list-item");
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      item.classList.remove("hidden");
    }
    showCount();
    setState("filter", "all");
    setButtonState();
    this.filterByBase();
  }
  filterByBase(e) {
    let selected_base = "";
    if (e === undefined) {
      console.log("undefined event");
      selected_base = document.getElementById("state").dataset.baseSelected;
      if (selected_base === "none") {
        return;
      }
    } else {
      console.log(
        "event target",
        e.target.options[e.target.selectedIndex].value
      );
      selected_base = e.target.options[e.target.selectedIndex].value;
      setState("base", selected_base);
    }
    console.log("SELECTED BASE", selected_base);
    let items = document.getElementsByClassName("medic-list-item");
    let completed_count = 0;
    let incomplete_count = 0;
    document.getElementById("state").dataset.filterSelected = "all";
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      item.classList.remove("hidden");
      let status = item.getElementsByClassName("base")[0].innerHTML;
      let compl = item.getElementsByClassName("completion-status")[0].innerHTML;
      if (status.trim() != selected_base) {
        item.classList.add("hidden");
      } else {
        if (item.dataset.completed === "completed") {
          completed_count = completed_count + 1;
        } else {
          incomplete_count = incomplete_count + 1;
        }
      }
    }
    updatePdfLink(selected_base);
    updatePie(completed_count, incomplete_count);
    showCount();
    setCompletionText();
    setButtonState();
  }
}
const updatePie = (completed_count, incomplete_count) => {
  let chart = Chartkick.charts["donut-chart"];
  let total = document.getElementsByClassName("medic-list-item").count;

  chart.updateData([
    ["Completed", completed_count],
    ["not completed", incomplete_count],
  ]);
};
const showCount = () => {
  let items = document.getElementsByClassName("medic-list-item");
  let found_count = document.getElementById("found-count");
  let hidden_items = document.querySelectorAll("li.hidden");

  found_count.innerHTML = `${items.length - hidden_items.length} found.`;
};
const buildSelector = () => {
  let base_array = getBases();
  let parent = document.getElementById("base-selector");
  var selectList = document.createElement("select");
  selectList.id = "mySelect";
  selectList.classList.add("custom-select");
  selectList.classList.add("custom-select-sm");
  selectList.dataset.action = "blreport#filterByBase";
  parent.appendChild(selectList);
  for (var i = 0; i < base_array.length; i++) {
    console.log("option");
    var option = document.createElement("option");
    option.value = base_array[i];
    option.text = base_array[i];
    selectList.appendChild(option);
  }
};
const getBases = () => {
  let items = document.getElementsByClassName("medic-list-item");
  let base_array = [];
  for (var i = 0; i < items.length; i++) {
    let item = items[i];
    let base = item.getElementsByClassName("base")[0].innerHTML;
    base_array.push(base.trim());
  }
  let distinct_array = [...new Set(base_array)];

  return distinct_array.sort();
};
const setState = (type, value) => {
  let state_container = document.getElementById("state");
  if (type === "filter") {
    state_container.dataset.filterSelected = value;
  } else {
    state_container.dataset.baseSelected = value;
  }
};
const setButtonState = () => {
  let state_container = document.getElementById("state");
  let state = state_container.dataset.filterSelected;
  let buttons = document.getElementsByClassName("filter-btn");
  for (var i = 0; i < buttons.length; i++) {
    let button = buttons[i];
    button.classList.remove("btn-primary");
    button.classList.add("btn-secondary");
  }
  let active = document.getElementById(state);
  active.classList.remove("btn-secondary");
  active.classList.add("btn-primary");
};
const setCompletionText = () => {
  let state_container = document.getElementById("state");
  let base_selected = state_container.dataset.baseSelected;
  let completed = 0;
  let incomplete = 0;
  if (base_selected === "none") {
    completed = document.querySelectorAll(
      "li[data-completed='completed']"
    ).length;
    incomplete = document.querySelectorAll(
      "li[data-completed='not_completed']"
    ).length;
  } else {
    completed = document.querySelectorAll(
      `li[data-completed='completed'][data-base='${base_selected}']`
    ).length;
    incomplete = document.querySelectorAll(
      `li[data-completed='not_completed'][data-base='${base_selected}']`
    ).length;
  }
  let completion_text = document.getElementById("bl-cme-completion-text");
  let completion_value = document.getElementById("bl-cme-completion-value");
  completion_text.innerHTML =
    base_selected === "none"
      ? "Completion at all bases"
      : `Completion at ${base_selected}`;
  completion_value.innerHTML = `${(
    (completed / (incomplete + completed)) *
    100
  ).toFixed(1)}%`;
};
const updatePdfLink = () => {
  let state = document.getElementById("state").dataset;
  let selected_base = state.baseSelected;
  let filter = state.filterSelected;
  let event_name = state.eventName;
  let link = document.getElementById("pdf-link");
  link.href = encodeURI(
    `/pdf_report?base=${selected_base}&filter=${filter}&event_name=${event_name}`
  );
};
