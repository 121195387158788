import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    console.log("Hello, sortable!", this.element);
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
    });
  }
  end(event) {
    console.log(event);
    let data = new FormData();
    let id = event.item.dataset.id;
    data.append("position", event.newIndex + 1);
    console.log("FORM", data);
    let url = this.data.get("url").replace(":id", id);
    console.log("URL", url);
    Rails.ajax({
      url: url,
      type: "PATCH",
      data: data,
    });
  }
}
