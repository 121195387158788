// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

const resetSelectButtons = () => {
  let nos = [1, 2, 3, 4, 5];
  for (let i = 0; i < nos.length; i++) {
    document.getElementById(`comp-rating-val-${nos[i]}`).className =
      "comp-rating-pill";
  }
};

export default class extends Controller {
  connect() {
    console.log("Hello, checked comp");
  }

  populateModal = (e) => {
    console.log("POPULATE", e.target.dataset.cr);
    let header = document.getElementById("exampleModalLabel");
    header.innerHTML = "";
    let pill = document.createElement("div");
    let header_row = document.createElement("div");
    let header_section = document.createElement("div");
    let comments_section = document.getElementById("modal-preceptor-comments");
    let subc_list = document.getElementById("subcompetencies-list");
    subc_list.innerHTML = null;
    header_row.classList.add("comp-rating-line");
    pill.classList.add("comp-rating-pill");
    $.ajax({
      url: `/competency_ratings/${e.target.dataset.cr}.json`,
      method: "GET",
      contentType: "application/json",
      success: function (response) {
        resetSelectButtons();
        let comp_rating = response.competency_rating;
        console.log("SUCCESS!!!!", response);
        let header_text = document.createTextNode(
          response.competency_rating.competency_name
        );
        let pill_text = document.createTextNode(
          `${comp_rating.rating_value} - ${comp_rating.rating_descriptor}`
        );
        pill.classList.add(`comp-rating-pill-val-${comp_rating.rating_value}`);
        pill.appendChild(pill_text);
        header_row.innerHTML = "";
        //header_row.appendChild(pill);
        header_section.appendChild(header_text);
        header_row.appendChild(header_section);
        header.appendChild(header_row);
        let rating_selector = document.getElementById(
          `comp-rating-val-${comp_rating.rating_value}`
        );
        rating_selector.classList.add(
          `comp-rating-pill-val-${comp_rating.rating_value}`
        );
        comments_section.innerHTML = comp_rating.comments;
        for (var i = 0; i < comp_rating.subcompetencies.length; i++) {
          let li = document.createElement("li");
          li.classList.add("list-group-item");
          li.innerHTML = comp_rating.subcompetencies[i].name;
          subc_list.appendChild(li);
        }
      },
    });
  };
}
