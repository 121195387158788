import { Controller } from "stimulus";
let MEDICS = [];

const updateSelectedList = (list) => {
  let ul = document.getElementById("evt-selected-learners-list");
  ul.innerHTML = "";
  list.forEach((learner) => {
    let li = document.createElement("li");
    let span = document.createElement("span");
    span.className = "pull-right";
    let icon = document.createElement("i");
    icon.setAttribute(
      "data-action",
      "click->event-participant-select#removeLearner"
    );
    span.appendChild(icon);
    icon.className = "fa fa-trash";
    li.className = "list-group-item evt-learner-select";
    li.textContent = `${learner.last_name}, ${learner.first_name}`;
    li.setAttribute("data-id", learner.id);
    li.appendChild(span);
    ul.appendChild(li);
    console.log("learner NAME", learner.name);
  });
  document.getElementById("medics").value = "";
};

const populatedSelectedWithCohort = (list) => {
  for (var i = 0; i < list.length; i++) {
    console.log("LIST ITEM", list[i]);
  }
};

const buildSelector = () => {
  let selector = document.createElement("select");
  selector.name = "medics";
  selector.id = "medics";
  selector.className = "form-control select evt-scen-cat";
  selector.setAttribute(
    "data-action",
    "change->event-participant-select#medicSelect"
  );
  let prompt = document.createElement("option");
  prompt.value = "";
  prompt.disabled = true;
  prompt.textContent = "Select Medic..";
  prompt.selected = "selected";
  selector.appendChild(prompt);
  for (var i = 0; i < MEDICS.length; i++) {
    let option = document.createElement("option");
    option.value = MEDICS[i].fm_id;
    option.textContent = MEDICS[i].name;
    selector.appendChild(option);
  }
  document.getElementById("medic-selector").appendChild(selector);
};

export default class extends Controller {
  connect() {
    fetch("/get_filemaker_medics.json")
      .then((response) => response.json())
      .then((json) => {
        console.log("LIST", json);
        MEDICS = json;
        buildSelector();
      });
  }
  medicSelect(e) {
    console.log("SELECTED", e.target.value);
    let learner_object = MEDICS.find((i) => i.fm_id === e.target.value);
    let data = {
      event: {
        ...learner_object,
        event_id: this.element.dataset.eventid,
        action: "add",
      },
    };
    console.log("DATA FOR POST", data);
    this.databaseUpdate(data);
  }

  cohortSelect = (e) => {
    let event_id = this.element.dataset.eventid;
    console.log("SELECTED", e.target.value);
    let cohort_id = e.target.value;
    this.addCohortMembers(event_id, cohort_id);
  };
  removeLearner(e) {
    let li = e.target.closest("li");
    let data = {
      event: {
        user_id: li.dataset.id,
        event_id: this.element.dataset.eventid,
        action: "remove",
      },
    };
    this.databaseUpdate(data);
  }
  populateSelectedList(list) {
    console.log("LIST", list);
    for (var i = 0; i < list.length; i++) {
      console.log("LIST ITEM", list[i]);
    }
  }
  databaseUpdate = (data) => {
    $.ajax({
      url: `/add_event_learners/`,
      method: "POST",
      contentType: "application/json",
      data: JSON.stringify(data),
      success: function (response) {
        console.log("RESP", response);
        updateSelectedList(response.users);
      },
      error: function (response) {
        console.log("ERROR", response);
      },
    });
  };
  addCohortMembers = (event_id, cohort_id) => {
    $.ajax({
      url: `/events/${event_id}/add_cohort_members/${cohort_id}`,
      method: "GET",
      contentType: "application/json",

      success: function (response) {
        console.log("Cohort_members", response);
        updateSelectedList(response.users);
      },
      error: function (response) {
        console.log("ERROR", response);
      },
    });
  };
}
